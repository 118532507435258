.main-trust-section {
    .title-h2 {
        margin-bottom: 20px;
    }
}
.main-trust {}
.main-trust-container {
    .flex();
    justify-content: space-around;
}
.main-trust-item {
    .width(12000px, 12, 2, 20px);
    .width(900px, 10, 2, 20px);
    .width(770px, 12, 3, 20px);
    .width(600px, 12, 4, 20px);
    .width(400px, 12, 6, 20px);
    .margin(10px);
    max-height: 150px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}