.main-portfolio {
    .flex();
}
.portfolio-item {
    .width(12000px, 12, 4, 20px);
    .width(950px, 12, 6, 20px);
    .width(600px, 12, 12, 20px);
    margin: 10px;
    height: 310px;
    background-position: center center;
    background-size: cover;
    @media screen and (max-width: 400px) {
        height: 250px;
    }
    a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        text-decoration: none;
        color: inherit;
        transition: background-color 1s;
        &:hover {
            background-color: rgba(255,255,255,0.4);
            .portfolio-item__title {
                background-color: var(--gold);
            }
        }
    }
    &__title {
        background-color: #c1bcad;
        width: 100%;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        transition: background-color 1s;
    }
}