header.site-header {
    a {
        text-decoration: none;
        color: inherit;
    }
}
.header-top-line {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: var(--darkGreen);
    // position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
    }
}
.logo-block {
    padding: 10px 15px;
    width: 200px;
    flex-shrink: 0;
    @media screen and (max-width: 1400px) {
        flex-shrink: inherit;
    }
    @media screen and (max-width: 1200px) {
        padding: 5px;
    }
    @media screen and (max-width: 650px) {
        flex-shrink: 1;
    }
    @media screen and (max-width: 450px) {
        width: calc(50% - 10px);
    }
    &__img {
        text-align: center;
        img {
            margin: auto;
            &.logo-scroll {
                width: 60px;
                margin-left: 20px;
            }
        }
        
    }
    &__desc {
        text-align: center;
        color: var(--lightGreen);
        &--small {
            display: block;
            font-size: 14px;
        }
        &--big {
            font-size: 18px;
            text-transform: uppercase;
            @media screen and (max-width: 365px) {
                font-size: 15px;
            }
        }
    }
}
.top-menu-block {
    padding-right: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1200px) {
        justify-content: center;
    }
    @media screen and (max-width: 450px) {
        width: calc(50% - 20px);
    }
}
.top-menu {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 45px;
    @media screen and (max-width: 1200px) {
        padding-right: 0;
    }
    nav {
        > ul {
            display: flex;
            list-style: none;
            justify-content: flex-end;
            width: 100%;
            max-width: 700px;
            justify-content: space-between;
            margin: 0;
            margin-left: auto;
            padding: 0;
            @media screen and (max-width: 1200px) {
                justify-content: center;
                max-width: 100%;
            }
            @media screen and (max-width: 650px) {
                display: block;
                text-align: center;
            }
            > li {
                margin: 0 10px;
                position: relative;
                color: var(--green);
                @media screen and (max-width: 900px) {
                    display: none;
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        display: block;
                    }
                }
                @media screen and (max-width: 500px) {
                    display: block;
                }
                @media screen and (max-width: 400px) {
                    margin: 0;
                    font-size: 16px;
                }
                &:hover ul.sub-menu {
                    visibility: visible;
                    opacity: 1;
                }
                ul.sub-menu {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.3s linear;
                    position: absolute;
                    top: 100%;
                    left: -20px;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    padding-top: 10px;
                    li {
                        padding: 5px 10px;
                        border: 1px solid var(--green);
                        border-bottom: none;
                        color: var(--green);
                        &:last-child {
                            border-bottom: 1px solid var(--green);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        order: 2;
    }
}
.top-phone {
    padding-right: 50px;
    color: white;
    text-align: right;
    padding-top: 20px;
    @media screen and (max-width: 1200px) {
        text-align: center;
        padding-right: 0;
        padding-top: 10px;
    }
    @media screen and (max-width: 400px) {
        font-size: 14px;
    }
}
.middle-menu {
    display: flex;
    align-items: flex-end;
    color: white;
    justify-content: flex-end;
    font-size: 15px;
    margin-top: auto;
    @media screen and (max-width: 1200px) {
        order: 3;
    }
    nav {
        flex-grow: 1;
        max-width: 1200px;
        @media screen and (max-width: 1200px) {
            display: none;
        }
        > ul {
            display: flex;
            list-style: none;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            align-items: stretch;
            > li {
                margin: 0 10px;
                padding-bottom: 20px;
                position: relative;
                max-width: 150px;
                text-transform: uppercase;
                text-align: center;
                display: flex;
                align-items: center;
                .font-size(1600px, 14px);
                &:hover ul.sub-menu {
                    visibility: visible;
                    opacity: 1;
                }
                ul.sub-menu {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0.1s, opacity 0.3s linear;
                    position: absolute;
                    top: 100%;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-color: var(--darkGreen);
                    border-top: 3px solid var(--gold);
                    li {
                        padding: 5px 15px;
                        text-transform: initial;
                        color: white;
                        width: 250px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
.call-designer.btn {
    margin: 0 20px;
    .font-size(1450px, 14px);
    margin-bottom: 10px;
    flex-shrink: 0;
    align-self: flex-start;
    @media screen and (max-width: 1400px) {
        display: none;
    }
}
.top-search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    align-self: center;
    img {
        width: 30px;
        cursor: pointer;
        @media screen and (max-width: 1400px) {
            margin-left: 15px;
        }
    }
}
.mobile-menu {
    width: 100%;
    color: white;
    max-height: 300px;
    transition: max-height 1s, opacity 1s;
    position: absolute;
    top: 100%;
    background-color: var(--darkGreen);
    ul.sub-menu {
        display: none;
    }
    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
            li {
                border-top: 2px solid white;
                padding: 5px 5px 5px 20px;
                text-transform: uppercase;
                @media screen and (max-width: 400px) {
                    font-size: 14px;
                }
                &:last-child {
                    border-bottom: 2px solid white;
                }
                &:hover {
                    background-color: white;
                    color: var(--gold);
                }
                a {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
    &.hide-menu {
        max-height: 0;
        overflow: hidden;
        display: none;
        opacity: 0;
        @media screen and (max-width: 1200px) {
            display: block;
        }
    }
}
.open-menu {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
        display: block;
    }
}
.f-search-form {
    position: absolute;
    bottom: -50px;
    right: 0;
    background-color: var(--darkGreen);
    padding: 10px;
    width: 100%;
    max-width: 350px;
    @media screen and (max-width: 500px) {
        max-width: calc(100% - 20px);
    }
    form {
        display: flex;
    }
    input[type=text] {
        border: none;
        outline: none;
        width: calc(100% - 30px) !important;
    }
    input[type=submit] {
        width: 30px;
        height: 30px;
        background-image: url('../img/search.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: black;
        border: none;
        outline: none;
        cursor: pointer;
    }
}