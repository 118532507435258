.main-slider {

}
.main-image {
    height: 500px;
    background-size: 50%;
    @media screen and (max-width: 1400px) {
        background-size: 55%;
    }
    @media screen and (max-width: 1300px) {
        background-size: 60%;
        height: 450px;
    }
    @media screen and (max-width: 1200px) {
        background-size: 65%;
    }
    @media screen and (max-width: 1100px) {
        background-size: 70%;
    }
    @media screen and (max-width: 1000px) {
        background-size: 75%;
        height: 400px;
    }
    @media screen and (max-width: 900px) {
        background-size: 80%;
    }
    @media screen and (max-width: 800px) {
        background-size: 90%;
    }
    @media screen and (max-width: 700px) {
        background-size: 100%;
    }
    @media screen and (max-width: 600px) {
        background-size: 100%;
        height: 300px;
    }
    @media screen and (max-width: 500px) {
        background-size: 110%;
    }
    @media screen and (max-width: 450px) {
        background-size: 120%;
    }
    @media screen and (max-width: 400px) {
        background-size: 130%;
        height: 280px;
    }
}
.needle-thread {
    background-color: var(--lightGreen);
    padding-bottom: 5px;
    margin-block-start: 5px;
    font-family: 'Caveat', cursive;
}
.text-and-needle {
    min-height: 70px;
    background-image: url(../img/needle.svg);
    background-size: 100px 70px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding-right: 100px;
    span {
        font-size: 22px;
        margin: 0 10px;
        @media screen and (max-width: 350px) {
            font-size: 20px;
        }
    }
}
.thread {
    height: 2px;
    background-image: url(../img/thread.svg);
    background-size: contain;
    width: calc(100% - 10px);
}