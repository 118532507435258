footer.site-footer {
    background-color: var(--darkGreen);
    color: var(--lightGreen);
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 50px;
    a {
        text-decoration: none;
        color: inherit;
    }
}
.footer-container {
    .flex();
}
.footer-item-menu {
    .width(12000px, 12, 9, 20px);
    .margin(10px);
    .width(1100px, 12, 12, 20px);
    @media screen and (max-width: 1100px) {
        order: 2;
    }
    nav {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        > ul {
            .flex();
            > li {
                .width(12000px, 12, 4, 20px);
                .width(600px, 12, 12, 20px);
                .margin(10px);
                > a {
                    background-color: var(--gold);
                    padding: 0 20px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    color: white;
                    cursor: pointer;
                    border-radius: 5px;
                }
                ul.sub-menu {
                    padding-top: 10px;
                    li {
                        text-transform: uppercase;
                        font-size: 15px;
                        padding: 5px;
                        border-bottom: 1px solid var(--green);
                        a {}
                    }
                }
            }
        }
    }
}
.footer-item-info {
    .width(12000px, 12, 3, 20px);
    .width(1100px, 12, 12, 20px);
    @media screen and (max-width: 1100px) {
        order: 1;
    }
    .margin(10px);
}
.footer-logo {}
.footer-phone {
    text-align: center;
    margin: 20px 0;
    color: var(--gold);
    font-weight: bold;
    font-size: 20px;
}
.footer-social {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1100px) {
        margin-bottom: 20px;
    }
    a {
        svg {
            width: 25px;
            max-height: 25px;
            margin: 5px;
        }
        svg:hover path {
            fill: var(--gold);
        }
    }
}