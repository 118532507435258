@import "normalize.less";
@import "slick.less";
@import "magnific-popup.less";
@font-face {
    font-family: Calibri;
    src: url("fonts/Arial.ttf");
}
/************** Variables *******************/
@wrapper_width: 1230px;
@wrapper_padding: 10px;
/************* <functions> *******************/ 
.flex(){
    display: flex;
    flex-wrap: wrap;
}
// Задаем параметры центрирующего блока
.wrapper(){
    max-width: @wrapper_width;
    margin: auto;
    padding-left: @wrapper_padding;
    padding-right: @wrapper_padding;
}
// Задаем параметры ширины элемента в зависимости от размера экрана
.width(@width:10000px, @number_columns:12, @element_columns:3, @margin:20px){
    @media screen and (max-width: @width) {
        .element_width(@n, @el, @m){
            width: calc(100% / @n * @el - @m);           
        }
        .element_width(@number_columns, @element_columns, @margin);
    }
}
// Задаем размер шрифта в зависимости от размера экрана
.font-size(@width, @size){
    @media screen and (max-width: @width) {
        font-size: @size;
    }
}
// Задаем параметры отрицательных отступов у флекс контейнера для прижатия элементов к краям, поскольку у  wrapper задан padding. Отступ задается размером отступа с одной стороны у дочернего элемента.
.margin(@margin){
    margin-left: @margin;
    margin-right: @margin;
}
/************* </functions> *******************/ 
:root {
    --gold: #9c854a;
    --lightGreen: #e4d88c;
    --green: #64a596;
    --darkGreen: #005f6c;
}
body {
    font-family: Calibri, sans-serif;
    font-size: 16px;
    min-height: 1500px;
}
.wrapper {
    .wrapper();
}
.title-h2 {
    text-transform: uppercase;
    font-weight: normal;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    letter-spacing: 5px;
    color: #333333;
    text-align: center;
}
.title-h2 span::after {
    content: "";
    border-bottom: 1px solid var(--gold);
    display: block;
    width: 50%;
    margin: auto;
    margin-top: 15px;
}
.title-desc {
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
    line-height: 25px;
}
.btn {
    background-color: var(--gold);
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
.btn a {
    text-decoration: none;
    color: inherit;
}
.main-btn .btn {
    padding-left: 40px;
    padding-right: 40px;
}
.main-btn {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}
@import 'header.less';
@import 'slider.less';
@import 'footer.less';
@import 'main-portfolio.less';
@import 'main-trust.less';
@import 'main-interior-fabrics.less';
@import 'main-cornices.less';

.hide {
    display: none;
}