.main-interior-section {
    background-color: #262626;
    padding-bottom: 20px;
    .title-h2 {
        color: white;
        padding-top: 50px;
    }
    .title-desc {
        color: white;
    }
}
.main-interior-container {
    display: flex;
    justify-content: center;
}